<template>
  <div class="credit-granted-table payment-duedates mb-8">
    <!-- HEADER PER DESKTOP -->
    <v-row no-gutters class="table-header text-uppercase">
      <v-col cols="2" class="text-center hidden-sm-and-down">
        {{ $t("paymentDueDate.creditGranted.header.cod") }}
      </v-col>
      <v-col cols="2" class="text-center hidden-sm-and-down">
        {{ $t("paymentDueDate.creditGranted.header.date") }}
      </v-col>
      <v-col cols="2" class="text-center hidden-sm-and-down">
        {{ $t("paymentDueDate.creditGranted.header.loyal") }}
      </v-col>
      <v-col cols="2" class="text-center hidden-sm-and-down">
        {{ $t("paymentDueDate.creditGranted.header.expired") }}
      </v-col>
      <v-col cols="2" class="text-center hidden-sm-and-down">
        {{ $t("paymentDueDate.creditGranted.header.busy") }}
      </v-col>
      <v-col cols="2" class="text-center hidden-sm-and-down">
        {{ $t("paymentDueDate.creditGranted.header.remaining") }}
      </v-col>
    </v-row>

    <v-list elevation="0" class="rows-list" v-if="creditGrantedList">
      <v-list-item
        v-for="(item, index) in creditGrantedList"
        v-bind:key="index"
        :class="{ zebra: index % 2 == 0 }"
      >
        <v-row class="row-card align-center" no-gutters>
          <v-col cols="6" sm="4" md="2" class="fields text-center text-md-left">
            <div class="table-header hidden-md-and-up">
              {{ $t("paymentDueDate.creditGranted.header.cod") }}
            </div>
            {{ item.codInt }}</v-col
          >
          <v-col cols="6" sm="4" md="2" class="fields text-center">
            <div class="table-header hidden-md-and-up">
              {{ $t("paymentDueDate.creditGranted.header.date") }}
            </div>
            {{ $dayjs(item.updateDate).format("DD/MM/YYYY") }}
          </v-col>
          <v-col cols="6" sm="4" md="2" class="fields text-center">
            <div class="table-header hidden-md-and-up">
              {{ $t("paymentDueDate.creditGranted.header.loyal") }}
            </div>
            {{ $n(item.trustAmount, "currency") }}
          </v-col>
          <v-col cols="6" sm="4" md="2" class="fields text-center">
            <div class="table-header hidden-md-and-up">
              {{ $t("paymentDueDate.creditGranted.header.expired") }}
            </div>
            {{ $n(item.overdueAmount, "currency") }}
          </v-col>
          <v-col cols="6" sm="4" md="2" class="fields text-center">
            <div class="table-header hidden-md-and-up">
              {{ $t("paymentDueDate.creditGranted.header.busy") }}
            </div>
            {{ $n(item.pendingAmount, "currency") }}
          </v-col>
          <v-col cols="6" sm="4" md="2" class="fields text-center">
            <div class="table-header hidden-md-and-up">
              {{ $t("paymentDueDate.creditGranted.header.remaining") }}
            </div>
            {{ $n(item.remainingAmount, "currency") }}
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import PaymentDueDatesService from "~/service/paymentDueDatesService";
export default {
  name: "CreditGrantedTable",
  data() {
    return {
      creditGrantedList: null
    };
  },
  methods: {
    async getListCreditGranted() {
      let _this = this;
      let res = await PaymentDueDatesService.duedateListCreditGranted();
      _this.creditGrantedList = res;
    }
  },
  created() {
    this.getListCreditGranted();
  }
};
</script>
