<template>
  <v-card
    color="primary lighten-1"
    elevation="0"
    class="pa-2 d-flex flex-column align-center  white--text"
  >
    <img class="help-img pa-3 mb-2" src="/img/help.svg" alt="help" />
    <h3 class="title text-center">
      <strong>{{ $t("leftColumnCards.support.title") }}</strong>
    </h3>
    <span class="description text-center mt-1 mb-4">{{
      $t("leftColumnCards.support.description")
    }}</span>
    <v-btn
      text
      depressed
      :to="{ name: 'Page', params: { pageName: 'servizio-clienti' } }"
      class="contact-button mb-3 primary--text white"
    >
      {{ $t("leftColumnCards.support.button") }}
    </v-btn>
  </v-card>
</template>
<style scoped lang="scss">
.help-img {
  width: 60px;
  height: 60px;
}
h3.title {
  line-height: 24px;
}
</style>
<script>
export default {
  name: "SupportCard"
};
</script>
