<template>
  <div v-if="category" class="category-title">
    <h2
      v-if="!category.metaData.category_info.HIDE_TITLE"
      class="pt-2 profile-title"
    >
      {{ title }}
    </h2>
    <div
      v-if="!category.metaData.category_info.HIDE_TITLE && subtitle"
      v-html="subtitle"
    ></div>
  </div>
</template>
<style scoped lang="scss">
h2.profile-title {
  color: var(--v-secondary-base);
  font-size: 20px;
  font-weight: bold;
}
</style>
<script>
import get from "lodash/get";

export default {
  name: "ProfileTitle",
  props: {
    category: { type: Object, required: true }
  },
  computed: {
    title() {
      let c = this.selectedCategory ? this.selectedCategory : this.category;
      return get(c, "metaData.category_info.TITLE", c.name);
    },
    subtitle() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    }
  }
};
</script>
